import React from 'react';

import './dots.css';

export default function SliderDots({
  slider,
  position,
  // colour,
  changeSlide,
  active,
}) {
  return (
    <ul
      style={{
        ...dotStyles,
        bottom: position,
        gridTemplateColumns: `repeat(${slider.length}, 1fr)`,
      }}
    >
      {slider.map((_, i) => {
        const key = `dot${i}`;
        return (
          <li key={key}>
            <button
              type="button"
              className={`button dots hidden ${active === i ? 'active' : ''}`}
              onClick={() => changeSlide(i)}
              aria-label={`View Slider ${i + 1}`}
              style={{
                ...iconStyle,
                // backgroundColor: colour,
              }}
            >
              View Slider {i + 1}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

const dotStyles = {
  position: `absolute`,
  left: `50%`,
  transform: `translate(-50%,0)`,
  zIndex: 10,
  display: `grid`,
  gridGap: `15px`,
};

const iconStyle = {
  fontSize: 0,
  color: `transparent`,
  height: `15px`,
  width: `15px`,
  borderRadius: `15px`,
  transition: `all 0.3s ease`,
};
